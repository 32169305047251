import { Select } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';
import { useCalendarGrids } from '../../api/school-calendar/calendar-day-info';

interface CalendarGridSelectFilterProps {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
}

export function CalendarGridSelectFilter({
  value,
  onChange,
}: CalendarGridSelectFilterProps) {
  const { t } = useTranslation(['timetable']);
  const { data: grids } = useCalendarGrids({});

  const gridOptions = useMemo(
    () => [
      {
        value: 'all',
        label: t('timetable:allGrids'),
      },
      ...(grids?.map(({ gridIdx, name }) => ({
        value: gridIdx,
        label: name,
      })) ?? []),
    ],
    [grids],
  );

  if (gridOptions.length < 3) return null;

  return (
    <Select
      label={t('timetable:grid')}
      value={value ?? 'all'}
      variant="white-filled"
      options={gridOptions}
      optionIdKey="value"
      optionTextKey="label"
      fullWidth
      sx={{ maxWidth: 160 }}
      onChange={({ target }) => {
        const newValue =
          target.value === 'all' ? undefined : Number(target.value);
        onChange(newValue);
      }}
    />
  );
}
