import { getUser } from '@tyro/api';
import {
  type NavObjectFunction,
  NavObjectType,
  lazyWithRetry,
} from '@tyro/core';
import { Calendar31Icon } from '@tyro/icons';
import dayjs from 'dayjs';
import { getCalendarEvents } from './api/events';
import {
  DEFAULT_CAL_DATE,
  DEFAULT_EVENT_TYPES,
} from './components/common/calendar/calendar';

const CalendarPage = lazyWithRetry(() => import('./pages/calendar'));

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootLink,
        path: 'calendar',
        hasAccess: (permissions) =>
          permissions.isStaffUserHasWithAtLeastOnePermission([
            'ps:1:calendar:view_own_calendar',
            'ps:1:calendar:view_calendar',
          ]),
        icon: <Calendar31Icon />,
        title: t('navigation:general.calendar'),
        loader: async ({ request }) => {
          const { activeProfile } = await getUser();

          const url = new URL(request.url);
          const defaultDateString = url.searchParams.get('date');
          const date = defaultDateString
            ? dayjs(defaultDateString).toDate()
            : DEFAULT_CAL_DATE;
          const visibleEventTypes = DEFAULT_EVENT_TYPES;

          return getCalendarEvents({
            date,
            resources: {
              partyIds: [activeProfile?.partyId ?? 0],
              roomIds: [],
            },
            eventTypeFilter: visibleEventTypes,
            period: 'week',
          });
        },
        element: <CalendarPage />,
      },
    ],
  },
];
