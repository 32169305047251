import type { GradeFilter } from '@tyro/api';
import { type CustomCellEditorProps, TableAutocomplete } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';
import {
  type ReturnTypeFromUseGrades,
  useGrades,
} from '../../api/grade-sets/grades';

export interface TableGradeAutocompleteProps
  extends CustomCellEditorProps<
    unknown,
    ReturnTypeFromUseGrades | ReturnTypeFromUseGrades[]
  > {
  multiple: boolean;
  filter: GradeFilter;
}

export function TableGradeAutocomplete(props: TableGradeAutocompleteProps) {
  const { t } = useTranslation(['common']);
  const { multiple, filter, ...restProps } = props;

  const { data, isLoading } = useGrades(filter);

  const options = useMemo(
    () => data?.sort((a, b) => b.end - a.end) ?? [],
    [data],
  );

  return (
    <TableAutocomplete<ReturnTypeFromUseGrades>
      {...restProps}
      options={options ?? []}
      getOptionLabel={(option) => option?.name ?? ''}
      optionIdKey="id"
      AutocompleteProps={{
        autoHighlight: true,
        loading: isLoading,
        loadingText: t('common:loading'),
        multiple,
      }}
    />
  );
}
