import { useQuery } from '@tanstack/react-query';
import {
  type CalendarFilter,
  type CalendarGridFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { schoolCalendarKeys } from './keys';

const calendarDayInfo = graphql(/* GraphQL */ `
  query calendarDayInfo($filter: CalendarFilter) {
    calendar_calendar(filter: $filter) {
      id
      dayInfo {
        date
        dayType
        startTime
        description
        endTime
        periods {
          type
          startTime
          endTime
        }
      }
    }
  }
`);

const calendarGridsInfo = graphql(/* GraphQL */ `
  query calendarGridInfo($filter: CalendarGridFilter) {
    calendar_grids(filter: $filter) {
      gridIdx
      name
    }
  }
`);

const calendarDayInfoQuery = (filter: CalendarFilter) => ({
  queryKey: schoolCalendarKeys.dayInfo(filter),
  queryFn: async () =>
    gqlClient.request(calendarDayInfo, {
      filter,
    }),
});

export function getCalendarDayInfo(filter: CalendarFilter) {
  return queryClient.fetchQuery(calendarDayInfoQuery(filter));
}

export function useCalendarDayInfo(filter: CalendarFilter) {
  return useQuery({
    ...calendarDayInfoQuery(filter),
    select: ({ calendar_calendar }) => calendar_calendar?.[0] ?? {},
  });
}

const calendarGridInfoQuery = (filter: CalendarGridFilter) => ({
  queryKey: schoolCalendarKeys.gridInfo(filter),
  queryFn: async () =>
    gqlClient.request(calendarGridsInfo, {
      filter,
    }),
});

export function useCalendarGrids(filter: CalendarGridFilter) {
  return useQuery({
    ...calendarGridInfoQuery(filter),
    select: ({ calendar_grids }) => calendar_grids ?? [],
  });
}

export type ReturnTypeFromCalendarDayInfo = UseQueryReturnType<
  typeof useCalendarDayInfo
>;
