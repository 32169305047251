import {
  Autocomplete,
  type AutocompleteProps,
  RHFAutocomplete,
  type RHFAutocompleteProps,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { FieldValues } from 'react-hook-form';
import {
  type StudentSelectOption,
  useStudentsForSelect,
} from '../../api/student/students';
import { usePeopleAutocompleteProps } from './use-people-autocomplete-props';

type RHFStudentAutocompleteProps<TField extends FieldValues> = Omit<
  RHFAutocompleteProps<TField, StudentSelectOption>,
  'options'
> & { hideAvatar?: boolean };

type StudentAutocompleteProps = Omit<
  AutocompleteProps<StudentSelectOption>,
  'options'
> & { hideAvatar?: boolean };

export const RHFStudentAutocomplete = <TField extends FieldValues>({
  hideAvatar,
  ...props
}: RHFStudentAutocompleteProps<TField>) => {
  const { t } = useTranslation(['common']);
  const { data: studentData, isLoading } = useStudentsForSelect({});
  const peopleAutocompleteProps =
    usePeopleAutocompleteProps<StudentSelectOption>({ hideAvatar });

  return (
    <RHFAutocomplete<TField, StudentSelectOption>
      label={t('common:student')}
      {...peopleAutocompleteProps}
      fullWidth
      loading={isLoading}
      options={studentData ?? []}
      {...props}
    />
  );
};

export const StudentAutocomplete = ({
  hideAvatar,
  ...props
}: StudentAutocompleteProps) => {
  const { t } = useTranslation(['common']);
  const { data: studentData, isLoading } = useStudentsForSelect({});
  const peopleAutocompleteProps =
    usePeopleAutocompleteProps<StudentSelectOption>({ hideAvatar });

  return (
    <Autocomplete
      label={t('common:student')}
      {...peopleAutocompleteProps}
      fullWidth
      optionIdKey="partyId"
      loading={isLoading}
      options={studentData ?? []}
      {...props}
    />
  );
};
