import {
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { getFileSizeLabel } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { BlankFileIcon, CloseIcon } from '@tyro/icons';
import { type Dispatch, type SetStateAction, useEffect } from 'react';

export type AttachedFile = Map<string, Partial<File> & { fileUrl: string }>;

export type AttachedFilesListProps = {
  attachFiles: AttachedFile;
  setAttachFiles: Dispatch<SetStateAction<AttachedFile>>;
};

export function AttachedFilesList({
  attachFiles,
  setAttachFiles,
}: AttachedFilesListProps) {
  const { t } = useTranslation(['common', 'mail']);

  useEffect(
    () => () => {
      for (const attachedFile of attachFiles.values()) {
        URL.revokeObjectURL(attachedFile.fileUrl);
      }
    },
    [],
  );

  const handleRemoveFile = (fileUrl: string) => {
    setAttachFiles((currentFiles) => {
      URL.revokeObjectURL(fileUrl);
      currentFiles.delete(fileUrl);

      return new Map(currentFiles);
    });
  };

  return (
    <Stack px={3}>
      <List
        dense
        disablePadding
        sx={{
          backgroundColor: 'transparent',
          '& .MuiListItem-root': {
            border: 'solid 2px',
            borderColor: 'indigo.50',
            backgroundColor: 'slate.50',
            py: 0.75,
            px: 1,
            my: 1,
            borderRadius: 1.25,
          },
        }}
      >
        {Array.from(attachFiles.values()).map(({ name, size, fileUrl }) => {
          const bytesToKB = ((size ?? 0) / 1024).toFixed(2);

          return (
            <ListItem
              key={fileUrl}
              dense
              disableGutters
              secondaryAction={
                <IconButton
                  size="small"
                  edge="end"
                  aria-label={t('mail:removeAttachment')}
                  onClick={() => handleRemoveFile(fileUrl)}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={
                  <Link
                    href={fileUrl}
                    fontWeight={600}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Stack alignItems="center" flexDirection="row" gap={0.75}>
                      <BlankFileIcon color="primary" strokeWidth={1} />
                      {name}{' '}
                      {size && (
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          fontWeight={600}
                        >
                          {`(${getFileSizeLabel(Number.parseFloat(bytesToKB))})`}
                        </Typography>
                      )}
                    </Stack>
                  </Link>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
}
