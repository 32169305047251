import { useMutation } from '@tanstack/react-query';
import {
  type UpdatePermissionSet,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { permissionsKeys } from './keys';

const updatePermissionSets = graphql(/* GraphQL */ `
  mutation users_updatePermissionSet($input: [UpdatePermissionSet!]!) {
    users_updatePermissionSet(input: $input) {
      success
    }
  }
`);

export function useUpdatePermissionSets() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: UpdatePermissionSet[]) =>
      gqlClient.request(updatePermissionSets, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: permissionsKeys.all });
      toast(t('common:snackbarMessages.updateSuccess'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}
