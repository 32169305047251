import { useQuery } from '@tanstack/react-query';
import {
  type Catalogue_SystemSettingsFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { systemKeys } from './keys';

const settingsView = graphql(/* GraphQL */ `
   query catalogue_systemSettingsView($filter: Catalogue_SystemSettingsFilter!) {
    catalogue_systemSettingsView(filter: $filter) {
      id {
        module
        category
        nameId
      }
    	label
      labelTextId
    	description
    	fieldType
      value
      selectValues{
        label
        value
      }
    }
  }
`);

const settingsViewQuery = (filter: Catalogue_SystemSettingsFilter) => ({
  queryKey: systemKeys.settingsView(filter),
  queryFn: () => gqlClient.request(settingsView, { filter }),
});

export function getSettingsView(filter: Catalogue_SystemSettingsFilter) {
  return queryClient.fetchQuery(settingsViewQuery(filter));
}

export function useSettingsView(filter: Catalogue_SystemSettingsFilter) {
  return useQuery({
    ...settingsViewQuery(filter),
    select: ({ catalogue_systemSettingsView }) =>
      catalogue_systemSettingsView.flatMap((view) => (view ? [view] : [])),
  });
}

export type ReturnTypeFromUseSettingsView = UseQueryReturnType<
  typeof useSettingsView
>[number];
