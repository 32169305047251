import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { GradeType, queryClient, usePermissions } from '@tyro/api';
import { TableStudyLevelChip } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { EditIcon } from '@tyro/icons';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { ReturnTypeFromUseAssessmentById } from '../../../api/assessments';
import { assessmentsKeys } from '../../../api/keys';
import type { ReturnTypeFromUseStudentAssessmentResults } from '../../../api/term-assessments/student-results';
import { getRowDetailsFromResult } from '../../../utils/get-row-details-from-result';
import { ColorCard } from './color-card';
import { useStudentAssessmentReportCardSettings } from './settings';

interface ReportCardResultTableProps {
  results: ReturnTypeFromUseStudentAssessmentResults;
  assessmentData: ReturnTypeFromUseAssessmentById;
}

type GridRowProps = {
  rowType?: 'header' | 'body';
  columns: {
    [key: string]: React.ReactNode;
  };
};

function getGridColumnWidth(index: number) {
  switch (index) {
    case 0:
      return '30%';
    case 1:
    case 2:
    case 3:
      return '15%';
    default:
      return undefined;
  }
}

function GridRow({ rowType = 'body', columns }: GridRowProps) {
  return (
    <Stack direction="row" role="row">
      {Object.entries(columns).map(([key, column], index) => (
        <Box
          key={key}
          role={rowType === 'header' ? 'columnheader' : 'gridcell'}
          aria-colindex={index + 1}
          sx={{
            width: getGridColumnWidth(index),
            flex: index === 4 ? 1 : undefined,
            p: 0.5,
          }}
          display="flex"
          justifyContent={[0, 4].includes(index) ? 'flex-start' : 'center'}
          alignItems="center"
        >
          {column}
        </Box>
      ))}
    </Stack>
  );
}

export function ReportCardResultTable({
  results,
  assessmentData,
}: ReportCardResultTableProps) {
  const leftByEditButtonRef = useRef<boolean>(false);
  const { t } = useTranslation(['common']);
  const { isContact } = usePermissions();
  const {
    academicNamespaceId,
    assessmentId,
    isMobile,
    isMobileCommentsShowing,
    showEditResult,
    studentPartyId,
  } = useStudentAssessmentReportCardSettings();

  const { extraFields, showType } = assessmentData ?? {};
  const showResult = [GradeType.Percentage, GradeType.Both].includes(showType);
  const showGrade = [GradeType.GradeSet, GradeType.Both].includes(showType);

  const mobileHeadings = [
    t('common:subject'),
    t('common:level'),
    ...(showResult ? [t('common:result')] : []),
    ...(showGrade ? [t('common:grade')] : []),
    t('common:teacher'),
  ];

  const extraFieldNames = useMemo(
    () =>
      (extraFields ?? []).reduce((acc, extraField) => {
        acc.set(extraField.id, extraField.name);
        return acc;
      }, new Map<number, string>()),
    [extraFields],
  );

  const getEditResultLink = useCallback(
    (subjectGroupId: number) => {
      if (
        !academicNamespaceId ||
        !assessmentId ||
        !subjectGroupId ||
        !showEditResult
      )
        return null;

      return (
        <IconButton
          component="a"
          target="_blank"
          href={`/assessments/${academicNamespaceId}/term-assessments/${assessmentId}/subject-group/${subjectGroupId}`}
          onClick={() => {
            leftByEditButtonRef.current = true;
          }}
          size="small"
        >
          <EditIcon />
        </IconButton>
      );
    },
    [academicNamespaceId, assessmentId, studentPartyId],
  );

  useEffect(() => {
    const handleFocus = () => {
      if (
        document.visibilityState === 'visible' &&
        leftByEditButtonRef.current
      ) {
        queryClient.invalidateQueries({
          queryKey: assessmentsKeys.assessmentResultsForStudent(
            academicNamespaceId,
            {
              studentPartyIds: [studentPartyId],
              assessmentId,
            },
          ),
        });
        leftByEditButtonRef.current = false;
      }
    };
    window.addEventListener('visibilitychange', handleFocus, false);
    return () => {
      window.removeEventListener('visibilitychange', handleFocus);
    };
  }, [academicNamespaceId, assessmentId, studentPartyId]);

  return isMobile ? (
    <Stack role="treegrid" px={1} pt={0.5} pb={1.5} spacing={0.5}>
      <GridRow
        rowType="header"
        columns={mobileHeadings.reduce<GridRowProps['columns']>(
          (acc, heading) => {
            acc[heading] = (
              <Typography
                key={heading}
                variant="subtitle2"
                component="span"
                color="text.secondary"
                fontSize="0.75rem"
              >
                {heading}
              </Typography>
            );
            return acc;
          },
          {},
        )}
      />
      {results?.map(({ id, ...studentResult }) => {
        const { subject, teacherNames, result, grade, studyLevel } =
          getRowDetailsFromResult(studentResult);

        return (
          <Stack
            key={`${id ?? 0}-${studentResult.subjectGroup.partyId}`}
            role="rowgroup"
          >
            <GridRow
              columns={{
                subject: (
                  <ColorCard
                    isMobile
                    key="subject"
                    text={subject?.name}
                    color={subject?.colour}
                  />
                ),
                level: <TableStudyLevelChip level={studyLevel} condensed />,
                result: showResult ? (
                  <Typography variant="body2" fontSize="0.75rem">
                    {typeof result === 'number' ? `${result}%` : '-'}
                  </Typography>
                ) : null,
                grade: showGrade ? (
                  <Typography variant="body2" fontSize="0.75rem">
                    {grade ?? '-'}
                  </Typography>
                ) : null,
                teacherNames: (
                  <Typography variant="body2" fontSize="0.75rem">
                    {teacherNames}
                  </Typography>
                ),
              }}
            />
            <Collapse unmountOnExit in={isMobileCommentsShowing}>
              <Stack px={0.5} pt={0.5} spacing={1}>
                <ColorCard
                  isMobile
                  text={studentResult?.teacherComment?.comment ?? '-'}
                />
                {studentResult.extraFields?.map((extraField) => (
                  <ColorCard
                    key={extraField.id}
                    isMobile
                    text={
                      <>
                        <strong>
                          {extraFieldNames.get(
                            extraField.assessmentExtraFieldId,
                          )}
                        </strong>
                        <Box component="span" fontWeight="400">
                          : {extraField?.result ?? '-'}
                        </Box>
                      </>
                    }
                  />
                ))}
              </Stack>
            </Collapse>
          </Stack>
        );
      })}
    </Stack>
  ) : (
    <TableContainer>
      <Table
        size="small"
        sx={({ palette }) => ({
          '& th, & td': {
            border: `1px solid ${palette.divider}`,
            px: 2,
            py: 1.25,
            verticalAlign: 'middle',
          },
          '& th': {
            background: 'transparent',
            color: 'text.secondary',
            fontWeight: 600,
            borderTop: 'none',
          },
          '& th:first-of-type, & td:first-of-type': {
            borderLeft: 'none',
            maxWidth: 180,
          },
          '& th:last-of-type, & td:last-of-type': {
            borderRight: 'none',
          },
          '& th:nth-of-type(2), & td:nth-of-type(2), & th:nth-of-type(3), & td:nth-of-type(3), & th:nth-of-type(4), & td:nth-of-type(4)':
            {
              textAlign: 'center',
              width: 100,
            },
          '& tr:last-of-type td': {
            borderBottom: 'none',
          },
          '& .result-cell': {
            pr: 1,
          },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell>{t('common:subject')}</TableCell>
            <TableCell>{t('common:level')}</TableCell>
            {showResult ? <TableCell>{t('common:result')}</TableCell> : null}
            {showGrade ? <TableCell>{t('common:grade')}</TableCell> : null}
            <TableCell>{t('common:teacher')}</TableCell>
            <TableCell>{t('common:comment')}</TableCell>
            {extraFields?.map((extraField) => (
              <TableCell key={extraField.id}>{extraField.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {results?.map(({ id, ...studentResult }) => {
            const { subject, teacherNames, result, grade, studyLevel } =
              getRowDetailsFromResult(studentResult);
            return (
              <TableRow
                key={`${id ?? 0}-${studentResult.subjectGroup.partyId}`}
              >
                <TableCell>
                  <ColorCard text={subject?.name} color={subject?.colour} />
                </TableCell>
                <TableCell>
                  <TableStudyLevelChip level={studyLevel} condensed />
                </TableCell>
                {showResult ? (
                  <TableCell
                    className={showEditResult ? 'result-cell' : undefined}
                  >
                    <Stack
                      component="span"
                      direction="row"
                      gap={0.25}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <span>
                        {typeof result === 'number' ? `${result}%` : '-'}
                      </span>
                      {getEditResultLink(studentResult.subjectGroup.partyId)}
                    </Stack>
                  </TableCell>
                ) : null}
                {showGrade ? (
                  <TableCell
                    className={showEditResult ? 'result-cell' : undefined}
                  >
                    <Stack
                      component="span"
                      direction="row"
                      gap={0.25}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <span>{grade ?? '-'}</span>
                      {getEditResultLink(studentResult.subjectGroup.partyId)}
                    </Stack>
                  </TableCell>
                ) : null}
                <TableCell>{teacherNames}</TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  {studentResult?.teacherComment?.comment ?? '-'}
                </TableCell>
                {extraFields?.map((extraField) => {
                  const extraFieldResult = studentResult.extraFields?.find(
                    ({ assessmentExtraFieldId }) =>
                      assessmentExtraFieldId === extraField.id,
                  );

                  return (
                    <TableCell key={extraField.id}>
                      {extraFieldResult?.result ?? '-'}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
