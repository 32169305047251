import { RHFSelect, type RHFSelectProps, Select } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useTimetableGridList } from '../../api/common/grids';

type GridOption = {
  value: number;
  label: string;
};
interface GridSelectFilterProps {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  timetableId: number;
}

export function GridSelectFilter({
  value,
  onChange,
  timetableId,
}: GridSelectFilterProps) {
  const { t } = useTranslation(['timetable']);
  const { data: grids } = useTimetableGridList({ timetableId });

  const gridOptions = useMemo(
    () => [
      {
        value: 'all',
        label: t('timetable:allGrids'),
      },
      ...(grids?.map(({ idx, name }) => ({
        value: idx,
        label: name,
      })) ?? []),
    ],
    [grids],
  );

  if (gridOptions.length <= 2) return null;

  return (
    <Select
      label={t('timetable:grid')}
      value={value ?? 'all'}
      variant="white-filled"
      options={gridOptions}
      optionIdKey="value"
      optionTextKey="label"
      fullWidth
      sx={{ maxWidth: 160 }}
      onChange={({ target }) => {
        const newValue =
          target.value === 'all' ? undefined : Number(target.value);
        onChange(newValue);
      }}
    />
  );
}

type RHFGridSelectProps<TField extends FieldValues> = Omit<
  RHFSelectProps<TField, GridOption>,
  'options' | 'getOptionLabel'
> & { timetableId: number };

export const RHFGridSelect = <TField extends FieldValues>({
  timetableId,
  ...props
}: RHFGridSelectProps<TField>) => {
  const { t } = useTranslation(['timetable']);
  const { data: grids } = useTimetableGridList({ timetableId });

  const gridOptions = useMemo<GridOption[]>(
    () =>
      grids?.map(({ idx, name }) => ({
        value: idx,
        label: name,
      })) ?? [],
    [grids],
  );

  if (gridOptions.length < 2) return null;

  return (
    <RHFSelect<TField, GridOption>
      fullWidth
      label={t('timetable:grid')}
      options={gridOptions}
      optionIdKey="value"
      optionTextKey="label"
      {...props}
    />
  );
};
