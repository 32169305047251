import type { Person } from '@tyro/api';
import { type AutocompleteProps, usePreferredNameLayout } from '@tyro/core';

type PersonAutocomplete = Omit<Person, '__typename'> & { caption?: string };

type UsePeopleAutocompleteProps = {
  hideAvatar?: boolean;
};

export const usePeopleAutocompleteProps = <T extends PersonAutocomplete>(
  props?: UsePeopleAutocompleteProps,
): Pick<
  AutocompleteProps<T>,
  | 'optionIdKey'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'renderAvatarTags'
  | 'renderAvatarOption'
  | 'renderAvatarAdornment'
> => {
  const { displayName, searchDisplayName } = usePreferredNameLayout();
  const { hideAvatar } = props || {};

  return {
    optionIdKey: 'partyId',
    getOptionLabel: (option) => displayName(option),
    filterOptions: (options, { inputValue }) =>
      searchDisplayName(options, inputValue),
    renderAvatarAdornment: hideAvatar
      ? () => null
      : (value, renderAdornment) =>
          renderAdornment({
            name: displayName(value),
            src: value.avatarUrl,
          }),
    renderAvatarTags: (option, renderTag) =>
      renderTag({
        name: displayName(option),
        src: option.avatarUrl ?? undefined,
        person: option,
        hideAvatar,
      }),
    renderAvatarOption: (option, renderOption) =>
      renderOption({
        name: displayName(option),
        src: option.avatarUrl ?? undefined,
        person: option,
        ...(option.caption && { caption: option.caption }),
        hideAvatar,
      }),
  };
};
