import { Box, Slider, Stack } from '@mui/material';
import { IconButtonWithTooltip } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { RotateItemIcon, ZoomMinusIcon, ZoomPlusIcon } from '@tyro/icons';
import { type MutableRefObject, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

export interface PartyPhotoEditorProps {
  editorRef: MutableRefObject<AvatarEditor | null>;
  image: File;
  size?: number;
}

export function PartyPhotoEditor({
  editorRef,
  image,
  size = 300,
}: PartyPhotoEditorProps) {
  const { t } = useTranslation(['common']);
  const [photoSettings, setPhotoSettings] = useState({
    rotate: 0,
    scale: 0,
  });

  return (
    <Stack justifyContent="center" alignItems="center">
      <Box sx={{ borderRadius: 1, overflow: 'hidden', display: 'flex' }}>
        <AvatarEditor
          ref={(editor) => {
            if (editor) {
              editorRef.current = editor;
            }
          }}
          width={size}
          height={size}
          borderRadius={size / 2}
          image={image}
          rotate={photoSettings.rotate}
          scale={1 + photoSettings.scale * 0.03}
        />
      </Box>
      <Stack
        direction="row"
        width="100%"
        maxWidth={350}
        spacing={4}
        justifyContent="space-between"
        mt={1}
      >
        <IconButtonWithTooltip
          onClick={() =>
            setPhotoSettings((previousSettings) => ({
              ...previousSettings,
              rotate: previousSettings.rotate + 90,
            }))
          }
          title={t('common:actions.rotate')}
          size="small"
        >
          <RotateItemIcon />
        </IconButtonWithTooltip>
        <Stack
          spacing={2}
          direction="row"
          sx={{ alignItems: 'center', flex: 1 }}
        >
          <ZoomMinusIcon />
          <Slider
            aria-label={t('common:actions.zoom')}
            value={photoSettings.scale}
            onChange={(_, value) => {
              setPhotoSettings((previousSettings) => ({
                ...previousSettings,
                scale: value as number,
              }));
            }}
          />
          <ZoomPlusIcon />
        </Stack>
      </Stack>
    </Stack>
  );
}
