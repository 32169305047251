import { SearchType } from '@tyro/api';
import { PreferredNameFormat, displayName } from '@tyro/core';
import type { TFunction } from '@tyro/i18n';
import type { ReturnTypeFromUseNoticeBoardDetails } from '../api/notice-board';
import type { NoticeBoardFormState } from './types';

export type FormatAssigneesItemProp = Partial<{
  name?: string | undefined;
  partyId?: number | undefined;
  avatarUrl?: string | null | undefined;
  person?: {
    partyId?: number | undefined;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    avatarUrl?: string | null | undefined;
  };
}>;

export type AutocompleteOption = {
  __typename:
    | 'GeneralGroup'
    | 'SubjectGroup'
    | 'Staff'
    | 'Student'
    | 'StudentContact'
    | 'YearGroupEnrollment'
    | 'ProgrammeStageEnrollment';
  text?: string;
  partyId: number;
  avatarUrl?: string | null;
  type: SearchType;
};

type PartyType = 'staff' | 'student' | 'contact';

function getSearchType(
  type: PartyType,
  typeName: ReturnTypeFromUseNoticeBoardDetails['assignedToParties'][number]['party']['__typename'],
) {
  if (type === 'student') {
    switch (typeName) {
      case 'GeneralGroup':
        return SearchType.GeneralGroupStudent;
      case 'SubjectGroup':
        return SearchType.SubjectGroupStudent;
      case 'YearGroupEnrollment':
        return SearchType.YearGroupStudent;
      default:
        return SearchType.Student;
    }
  }

  if (type === 'contact') {
    switch (typeName) {
      case 'GeneralGroup':
        return SearchType.GeneralGroupContact;
      case 'SubjectGroup':
        return SearchType.SubjectGroupContact;
      case 'YearGroupEnrollment':
        return SearchType.YearGroupEnrollment;
      default:
        return SearchType.Contact;
    }
  }

  switch (typeName) {
    case 'GeneralGroup':
      return SearchType.GeneralGroupStaff;
    case 'SubjectGroup':
      return SearchType.SubjectGroupStaff;
    case 'YearGroupEnrollment':
      return SearchType.YearGroupStaff;
    default:
      return SearchType.Staff;
  }
}

export function formatAssignees(
  item: ReturnTypeFromUseNoticeBoardDetails['assignedToParties'][number],
  t: TFunction<['noticeBoard']>,
): AutocompleteOption {
  const { party, staff, student, contact } = item;

  let type: 'staff' | 'student' | 'contact' = 'staff';
  if (student) {
    type = 'student';
  } else if (contact) {
    type = 'contact';
  }

  switch (party.__typename) {
    case 'GeneralGroup':
    case 'SubjectGroup':
      return {
        __typename: party.__typename,
        text: t(`noticeBoard:assigneeOptionSuffix.${type}`, {
          name: party.name,
        }),
        partyId: party.partyId,
        avatarUrl: party?.avatarUrl,
        type: getSearchType(type, party.__typename),
      };
    case 'ProgrammeStageEnrollment':
    case 'YearGroupEnrollment':
      return {
        __typename: party.__typename,
        text: t(`noticeBoard:assigneeOptionSuffix.${type}`, {
          name: party.name,
        }),
        partyId: party.partyId,
        avatarUrl: undefined,
        type: getSearchType(type, party.__typename),
      };

    case 'Staff':
    case 'Student':
    case 'StudentContact':
      return {
        __typename: party.__typename,
        text: displayName(party.person, {
          format: PreferredNameFormat.FirstnameSurname,
        }),
        partyId: party?.partyId,
        avatarUrl: party?.person?.avatarUrl,
        type: getSearchType(type, party.__typename),
      };
  }
}

export function getAssigneesForRequest(
  noticeAssignees: NoticeBoardFormState['noticeAssignees'],
) {
  const assignees =
    noticeAssignees?.map(({ partyId, type }) => ({
      id: partyId,
      staff: [
        SearchType.Staff,
        SearchType.GeneralGroupStaff,
        SearchType.SubjectGroupStaff,
        SearchType.YearGroupStaff,
        SearchType.CustomGroup,
      ].includes(type),
      contact: [
        SearchType.Contact,
        SearchType.GeneralGroupContact,
        SearchType.SubjectGroupContact,
        SearchType.YearGroupContact,
      ].includes(type),
      student: false,
    })) ?? [];

  return {
    allStaff: assignees.some(({ id }) => id === -1),
    allContacts: assignees.some(({ id }) => id === -2),
    assignees: assignees?.filter(({ id }) => id > 0),
  };
}
