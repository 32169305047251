import {
  type Comment,
  ExtraFieldType,
  type UsePermissionsReturn,
} from '@tyro/api';
import { type GridOptions, TableSelect } from '@tyro/core';
import set from 'lodash/set';
import type { ReturnTypeFromUseAssessmentResults } from '../api/assessment-results';
import type { ReturnTypeFromUseAssessmentById } from '../api/assessments';
import type { ReturnTypeFromUseCommentBanksWithComments } from '../api/comment-bank';

type ColumnDefs = NonNullable<
  GridOptions<ReturnTypeFromUseAssessmentResults>['columnDefs']
>;

export function getExtraFields(
  extraFields: ReturnTypeFromUseAssessmentById['extraFields'],
  permissions: UsePermissionsReturn,
  commentBanks: ReturnTypeFromUseCommentBanksWithComments | undefined,
): ColumnDefs {
  const commentBackById = new Map(
    commentBanks?.map((cb) => [
      cb.id,
      {
        byId: new Map(cb.comments?.map((c) => [c.id, c]) ?? []),
        byLabel: new Map(cb.comments?.map((c) => [c.comment, c]) ?? []),
        comments: cb.comments ?? [],
      },
    ]),
  );

  return (
    extraFields?.map((extraField) => {
      const matchedCommentBank = commentBackById.get(
        extraField?.commentBankId ?? 0,
      ) ?? {
        byId: new Map(),
        byLabel: new Map(),
        comments: [],
      };

      const commonFields = {
        headerName: extraField?.name ?? '',
        editable: permissions.hasPermission(
          'ps:1:assessment:write_assessment_result',
        ),
      };

      switch (extraField?.extraFieldType) {
        case ExtraFieldType.CommentBank:
          return {
            ...commonFields,
            field: `extraFields.${extraField.id}.commentBankCommentId`,
            valueFormatter: ({ value }) => {
              const matchedComment =
                matchedCommentBank.byId.get(value) ??
                matchedCommentBank.byLabel.get(value);

              return matchedComment?.comment ?? (value as string);
            },
            valueGetter: ({ data }) => {
              const extraFieldValues = data?.extraFields ?? {};
              const matchedExtraField = extraFieldValues[extraField.id];
              return matchedExtraField?.commentBankCommentId;
            },
            valueSetter: ({ data, newValue }) => {
              const resolvedValue =
                matchedCommentBank.byId.get(newValue) ??
                matchedCommentBank.byLabel.get(newValue);

              set(
                data ?? {},
                `extraFields.${extraField.id}.commentBankCommentId`,
                resolvedValue?.id,
              );
              set(
                data ?? {},
                `extraFields.${extraField.id}.assessmentExtraFieldId`,
                extraField.id,
              );
              return true;
            },
            cellEditorSelector: () => ({
              component: TableSelect,
              popup: true,
              popupPosition: 'under',
              params: {
                options:
                  matchedCommentBank?.comments?.filter(
                    (comment) => comment?.active,
                  ) || [],
                optionIdKey: 'id',
                getOptionLabel: (option: Comment) => option.comment,
              },
            }),
          };
        default:
          return {
            ...commonFields,
            field: `extraFields.${extraField.id}.result`,
            valueGetter: ({ data }) => {
              const extraFieldValues = data?.extraFields ?? {};
              const matchedExtraField = extraFieldValues[extraField.id];
              return matchedExtraField?.result;
            },
            valueSetter: ({ data, newValue }) => {
              set(data ?? {}, `extraFields.${extraField.id}.result`, newValue);
              set(
                data ?? {},
                `extraFields.${extraField.id}.assessmentExtraFieldId`,
                extraField.id,
              );
              return true;
            },
            cellEditorSelector: () => ({
              component: 'agLargeTextCellEditor',
              popup: true,
              params: {
                maxLength: extraField?.commentLength ?? 2000,
              },
            }),
          };
      }
    }) ?? []
  );
}
