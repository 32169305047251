import {
  type Comment,
  CommentType,
  type UsePermissionsReturn,
} from '@tyro/api';
import {
  type GridOptions,
  type ReturnOfUseToast,
  TableSelect,
  type ValueFormatterParams,
  type ValueSetterParams,
} from '@tyro/core';
import type { TFunction } from '@tyro/i18n';
import set from 'lodash/set';
import type { ReturnTypeFromUseAssessmentResults } from '../api/assessment-results';
import type { ReturnTypeFromUseAssessmentById } from '../api/assessments';
import type { ReturnTypeFromUseCommentBanksWithComments } from '../api/comment-bank';
import { CommentTypeCellEditor } from '../components/common/comment-type-cell-editor';

type ColumnDefs = NonNullable<
  GridOptions<ReturnTypeFromUseAssessmentResults>['columnDefs']
>;

const editAssessmentPermission = 'ps:1:assessment:write_assessment_result';

export function getCommentFields(
  assessmentData: ReturnTypeFromUseAssessmentById | null | undefined,
  permissions: UsePermissionsReturn,
  commentBanks: ReturnTypeFromUseCommentBanksWithComments | undefined,
  t: TFunction<
    ('common' | 'assessments')[],
    undefined,
    ('common' | 'assessments')[]
  >,
  toast: ReturnOfUseToast['toast'],
): ColumnDefs {
  if (assessmentData?.commentType === CommentType.None) {
    return [];
  }

  const matchedCommentBank =
    commentBanks?.find(
      (commentBank) =>
        commentBank.id === assessmentData?.commentBank?.commentBankId,
    )?.comments || [];

  const commentTypeByValue = new Map(
    ([CommentType.CommentBank, CommentType.FreeForm] as const).flatMap(
      (value) => [
        [value, value],
        [t(`assessments:labels.commentTypes.${value}`), value],
      ],
    ),
  );

  const getIsCommentBankSelector = (
    data: ReturnTypeFromUseAssessmentResults | undefined,
  ) =>
    assessmentData?.commentType === CommentType.CommentBank ||
    data?.teacherCommentType === CommentType.CommentBank;

  const commentBankOptions = matchedCommentBank?.filter(
    (comment) => comment?.active,
  );

  return [
    ...(assessmentData?.commentType === CommentType.Both
      ? [
          {
            field: 'teacherCommentType',
            headerName: t('assessments:labels.commentType'),
            editable: permissions.hasPermission(editAssessmentPermission),
            cellEditorSelector: CommentTypeCellEditor(t),
            valueSetter: ({
              data,
              newValue,
            }: ValueSetterParams<
              ReturnTypeFromUseAssessmentResults,
              string
            >) => {
              const resolvedValue =
                commentTypeByValue.get(newValue) ?? newValue;
              set(data ?? {}, 'teacherCommentType', resolvedValue);
              const isCommentBankSelector = getIsCommentBankSelector(data);

              if (isCommentBankSelector) {
                if (!commentBankOptions?.length) {
                  toast(t('assessments:noActiveCommentsInCommentBank'), {
                    variant: 'error',
                  });
                  return false;
                }

                set(
                  data ?? {},
                  'teacherComment.commentBankCommentId',
                  data.teacherComment?.commentBankCommentId ??
                    commentBankOptions[0].id,
                );
              }

              set(data ?? {}, 'teacherComment.comment', null);

              return true;
            },
            valueFormatter: ({
              value,
            }: ValueFormatterParams<
              ReturnTypeFromUseAssessmentResults,
              CommentType.CommentBank | CommentType.FreeForm
            >) => (value ? t(`assessments:labels.commentTypes.${value}`) : ''),
          } as ColumnDefs[number],
        ]
      : []),
    {
      field: 'teacherComment',
      headerName: t('common:comment'),
      editable: permissions.hasPermission(editAssessmentPermission),
      autoHeight: true,
      wrapText: true,
      width: 350,
      cellStyle: {
        lineHeight: 2,
        paddingTop: 12,
        paddingBottom: 12,
        wordBreak: 'break-word',
      },
      cellEditorSelector: ({ data }) => {
        const isCommentBankSelector = getIsCommentBankSelector(data);

        return isCommentBankSelector
          ? {
              component: TableSelect,
              popup: true,
              popupPosition: 'under',
              params: {
                options: commentBankOptions,
                optionIdKey: 'id',
                getOptionLabel: (option: Comment) => option.comment,
              },
            }
          : {
              component: 'agLargeTextCellEditor',
              popup: true,
              params: {
                maxLength: assessmentData?.commentLength ?? 2000,
                rows: 10,
              },
            };
      },
      valueGetter: ({ data }) => {
        const isCommentBankSelector = getIsCommentBankSelector(data);

        return isCommentBankSelector
          ? data?.teacherComment?.commentBankCommentId
          : data?.teacherComment?.comment;
      },
      valueSetter: ({
        data,
        newValue,
      }: ValueSetterParams<
        ReturnTypeFromUseAssessmentResults,
        string | number | ReturnTypeFromUseAssessmentResults['teacherComment']
      >) => {
        const isCommentBankSelector = getIsCommentBankSelector(data);

        if (newValue) {
          if (typeof newValue === 'object') {
            set(
              data ?? {},
              'teacherComment.commentBankCommentId',
              newValue?.commentBankCommentId,
            );
            set(data ?? {}, 'teacherComment.comment', newValue?.comment);
            return true;
          }

          if (isCommentBankSelector) {
            const matchedComment = matchedCommentBank?.find(
              (comment) =>
                comment.id === newValue || comment.comment === newValue,
            );
            set(
              data ?? {},
              'teacherComment.commentBankCommentId',
              matchedComment?.id,
            );
          } else {
            set(data ?? {}, 'teacherComment.comment', newValue);
          }
        } else {
          set(data ?? {}, 'teacherComment.comment', null);
          set(data ?? {}, 'teacherComment.commentBankCommentId', null);
        }

        return true;
      },
      valueFormatter: ({ data, value }) => {
        const isCommentBankSelector = getIsCommentBankSelector(data);

        if (isCommentBankSelector) {
          const matchedComment = matchedCommentBank?.find(
            (comment) => comment.id === value,
          );

          return matchedComment?.comment ?? (value as string);
        }

        return value as string;
      },
    },
  ];
}
