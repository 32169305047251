import { useQuery } from '@tanstack/react-query';
import {
  type GradeFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { assessmentsKeys } from '../keys';

const grades = graphql(/* GraphQL */ `
  query assessment_grades($filter: GradeFilter) {
    assessment_grades(filter: $filter) {
      id
      name
      start
      end
      active
      studyLevels
    }
  }
`);

const gradesQuery = (filter: GradeFilter) => ({
  queryKey: assessmentsKeys.grades(filter),
  queryFn: async () => {
    const { assessment_grades } = await gqlClient.request(grades, { filter });
    return assessment_grades ?? [];
  },
});

export function getGrades(filter: GradeFilter) {
  return queryClient.fetchQuery(gradesQuery(filter));
}

export function useGrades(filter: GradeFilter) {
  return useQuery({
    ...gradesQuery(filter),
  });
}

export type ReturnTypeFromUseGrades = UseQueryReturnType<
  typeof useGrades
>[number];
