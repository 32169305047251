import { StudyLevel, type UsePermissionsReturn } from '@tyro/api';
import {
  type ICellRendererParams,
  StudyLevelSelectCellEditor,
  TableStudyLevelChip,
  type ValueSetterParams,
} from '@tyro/core';
import type { TFunction } from '@tyro/i18n';
import set from 'lodash/set';
import type { ReturnTypeFromUseAssessmentResults } from '../api/assessment-results';
import type { ReturnTypeFromUseAssessmentById } from '../api/assessments';
import type { ReturnTypeFromUseGrades } from '../api/grade-sets/grades';
import { checkAndSetGrades } from './check-and-set-grades';

export function getStudyLevelCell({
  assessmentData,
  gradeById,
  permissions,
  t,
  editAssessmentPermission,
}: {
  assessmentData: ReturnTypeFromUseAssessmentById | null | undefined;
  gradeById: Map<number, ReturnTypeFromUseGrades>;
  permissions: UsePermissionsReturn;
  t: TFunction<('common' | 'assessments')[]>;
  editAssessmentPermission: string;
}) {
  return {
    field: 'studentStudyLevel',
    headerName: t('common:level'),
    editable: permissions.hasPermission(editAssessmentPermission),
    valueSetter: (
      params: ValueSetterParams<
        ReturnTypeFromUseAssessmentResults,
        StudyLevel | null
      >,
    ) => {
      const validatedNewValue =
        params.newValue && Object.values(StudyLevel).includes(params.newValue)
          ? params.newValue
          : null;

      if (validatedNewValue === params.oldValue) return false;

      set(params.data ?? {}, 'studentStudyLevel', validatedNewValue);

      if (assessmentData) {
        checkAndSetGrades({
          changedField: 'studentStudyLevel',
          assessmentData,
          gradeById,
          params,
        });
      }

      return true;
    },
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseAssessmentResults>) =>
      data?.studentStudyLevel ? (
        <TableStudyLevelChip level={data.studentStudyLevel} />
      ) : null,
    cellEditorSelector: StudyLevelSelectCellEditor(t),
  } as const;
}
