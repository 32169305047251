import {
  type NavObjectFunction,
  NavObjectType,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { NoticeBoardIcon } from '@tyro/icons';
import { getNoticeBoardDetails } from './api/notice-board';
const CreateNoticeTemplatePage = lazyWithRetry(() => import('./pages/create'));

const NoticeBoardPage = lazyWithRetry(() => import('./pages/index'));

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootLink,
        path: 'notice-board',
        title: t('navigation:general.noticeBoards.title'),
        hasAccess: ({ isStaffUserWithPermission }) =>
          isStaffUserWithPermission('ps:1:communications:write_noticeboard'),
        icon: <NoticeBoardIcon />,
        children: [
          {
            type: NavObjectType.NonMenuLink,
            index: true,
            element: <NoticeBoardPage />,
          },
          {
            path: 'create',
            type: NavObjectType.NonMenuLink,
            element: <CreateNoticeTemplatePage />,
          },
          {
            path: 'edit/:id',
            type: NavObjectType.NonMenuLink,
            element: <CreateNoticeTemplatePage />,
            loader: ({ params }) => {
              const id = getNumber(params.id);

              if (!id) {
                throw404Error();
              }
              return getNoticeBoardDetails({
                admin: true,
                archived: false,
                noticeIds: [id],
              });
            },
          },
          {
            path: 'edit/achieved/:id',
            type: NavObjectType.NonMenuLink,
            element: <CreateNoticeTemplatePage />,
            loader: ({ params }) => {
              const id = getNumber(params.id);

              if (!id) {
                throw404Error();
              }
              return getNoticeBoardDetails({
                admin: true,
                archived: true,
                noticeIds: [id],
              });
            },
          },
        ],
      },
    ],
  },
];
