import type { StaffFilter, UseQueryReturnType } from '@tyro/api';
import {
  Autocomplete,
  type AutocompleteProps,
  RHFAutocomplete,
  type RHFAutocompleteProps,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { FieldValues } from 'react-hook-form';
import { useStaffForSelect } from '../../api/staff';
import { usePeopleAutocompleteProps } from './use-people-autocomplete-props';

export type StaffSelectOption = UseQueryReturnType<
  typeof useStaffForSelect
>[number];

type RHFStaffAutocompleteProps<TField extends FieldValues> = Omit<
  RHFAutocompleteProps<TField, StaffSelectOption>,
  'options'
> & { filter?: StaffFilter; hideAvatar?: boolean };

type StaffAutocompleteProps = Omit<
  AutocompleteProps<StaffSelectOption>,
  'options'
> & { filter?: StaffFilter; hideAvatar?: boolean };

export const RHFStaffAutocomplete = <TField extends FieldValues>({
  filter,
  hideAvatar,
  ...props
}: RHFStaffAutocompleteProps<TField>) => {
  const { t } = useTranslation(['common']);
  const { data: teacherData, isLoading } = useStaffForSelect(filter ?? {});
  const peopleAutocompleteProps = usePeopleAutocompleteProps<StaffSelectOption>(
    { hideAvatar },
  );

  return (
    <RHFAutocomplete<TField, StaffSelectOption>
      label={t('common:staffMember')}
      {...peopleAutocompleteProps}
      fullWidth
      loading={isLoading}
      options={teacherData ?? []}
      {...props}
    />
  );
};

export const StaffAutocomplete = ({
  filter,
  hideAvatar,
  ...props
}: StaffAutocompleteProps) => {
  const { t } = useTranslation(['common']);
  const { data: teacherData, isLoading } = useStaffForSelect(filter ?? {});
  const peopleAutocompleteProps = usePeopleAutocompleteProps<StaffSelectOption>(
    {
      hideAvatar,
    },
  );

  return (
    <Autocomplete
      label={t('common:staffMember')}
      {...peopleAutocompleteProps}
      fullWidth
      loading={isLoading}
      options={teacherData ?? []}
      {...props}
    />
  );
};
