import { useQuery } from '@tanstack/react-query';
import {
  MemberType,
  type PermissionSetFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { permissionsKeys } from './keys';

const permissionSets = graphql(/* GraphQL */ `
  query users_permissionSets($filter: PermissionSetFilter!) {
    users_permissionSets(filter: $filter) {
      id
      name
      description
      permissionType
      toggle
      feature
      context
      visibleToSchools
      contact
      staff
      student
    }
  }
`);

const permissionSetsQuery = (filter: PermissionSetFilter) => ({
  queryKey: permissionsKeys.permissionSets(filter),
  queryFn: () => gqlClient.request(permissionSets, { filter }),
});

export function getPermissionSets(filter: PermissionSetFilter) {
  return queryClient.fetchQuery(permissionSetsQuery(filter));
}

const filters: Record<MemberType, PermissionSetFilter> = {
  [MemberType.Admin]: {
    staff: true,
  },
  [MemberType.Staff]: {
    staff: true,
  },
  [MemberType.Contact]: {
    contact: true,
  },
  [MemberType.Student]: {
    student: true,
  },
  [MemberType.External]: {},
  [MemberType.ThirdParty]: {},
};

export function usePermissionSets(memberType?: MemberType) {
  const filter = memberType ? filters[memberType] : {};

  return useQuery({
    ...permissionSetsQuery(filter),
    enabled: !!filter,
    select: ({ users_permissionSets }) =>
      Array.isArray(users_permissionSets)
        ? users_permissionSets.flatMap((permission) =>
            permission ? [permission] : [],
          )
        : [],
  });
}

export type ReturnTypeFromUsePermissionSets = UseQueryReturnType<
  typeof usePermissionSets
>[number];
