import { Box, Chip, Stack, Typography } from '@mui/material';
import {
  type FormsAdmin_FormDefinitionsFilter,
  getColorBasedOnIndex,
} from '@tyro/api';
import { RHFAutocomplete, type RHFAutocompleteProps } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { FieldValues } from 'react-hook-form';
import {
  type ReturnTypeFromUseFormDefinitionsForSelect,
  useFormForSelectDefinitions,
} from '../../api/forms-definitions';

export type FormAutocompleteOption = ReturnTypeFromUseFormDefinitionsForSelect;

type RHFFormAutocompleteProps<TField extends FieldValues> = Omit<
  RHFAutocompleteProps<TField, FormAutocompleteOption>,
  'options'
> & { filter?: FormsAdmin_FormDefinitionsFilter };

export const RHFFormAutocomplete = <TField extends FieldValues>({
  filter = { ids: [] },
  ...props
}: RHFFormAutocompleteProps<TField>) => {
  const { t } = useTranslation(['forms']);
  const { data: formOptions, isLoading } = useFormForSelectDefinitions(filter);

  return (
    <RHFAutocomplete<TField, FormAutocompleteOption>
      label={t('forms:formTemplate')}
      fullWidth
      loading={isLoading}
      options={formOptions ?? []}
      optionTextKey="title"
      getOptionKey={(option) => JSON.stringify(option.id)}
      {...props}
      filterOptions={(options, { inputValue }) => {
        if (!inputValue) {
          return options;
        }

        const lowerCaseInputValue = inputValue.toLowerCase();

        return options.filter(
          (option) =>
            option.title.toLowerCase().includes(lowerCaseInputValue) ||
            option?.description?.toLowerCase().includes(lowerCaseInputValue) ||
            option.tags.some((tag) =>
              tag.name.toLowerCase().includes(lowerCaseInputValue),
            ),
        );
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box key={key} component="li" {...optionProps}>
            <Stack>
              <Stack direction={'row'} gap={1}>
                <Typography>{option.title}</Typography>
                {(option.tags || []).map((tag) => (
                  <Chip
                    key={tag.id}
                    size="small"
                    variant="soft"
                    color={getColorBasedOnIndex(tag.id)}
                    label={tag.name}
                  />
                ))}
              </Stack>
              <Typography variant="caption">{option.description}</Typography>
            </Stack>
          </Box>
        );
      }}
    />
  );
};
