import type { NoticeboardFilter } from '@tyro/api';

export const noticeBoardKeys = {
  all: ['noticeBoard'] as const,
  noticeBoard: (filter: NoticeboardFilter) =>
    [...noticeBoardKeys.all, 'noticeBoard', filter] as const,
  noticeBoardDetails: (filter: NoticeboardFilter) =>
    [...noticeBoardKeys.all, 'noticeBoardDetails', filter] as const,
  search: (query: string) => [...noticeBoardKeys.all, 'search', query] as const,
};
