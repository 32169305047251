import {
  Card,
  CardHeader,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  type SvgIconProps,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useTranslation } from '@tyro/i18n';
import { BookOpenWithTextIcon, TeaCupIcon } from '@tyro/icons';

import { CalendarGridPeriodType } from '@tyro/api';

import {
  type ReactElement,
  forwardRef,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers';
import {
  DateTimePicker,
  type DateTimePickerProps,
  LoadingPlaceholderContainer,
  useDisclosure,
  useMergeRefs,
} from '@tyro/core';
import dayjs, { type Dayjs } from 'dayjs';
import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import { useCalendarGridPeriods } from '../../api/grid-periods';

const iconByGridType: Record<
  CalendarGridPeriodType,
  ReactElement<SvgIconProps>
> = {
  [CalendarGridPeriodType.Class]: (
    <BookOpenWithTextIcon sx={{ width: 20, height: 20 }} />
  ),
  [CalendarGridPeriodType.Break]: <TeaCupIcon sx={{ width: 20, height: 20 }} />,
};

export type SelectGridPeriodProps = {
  label?: string;
  value?: Dayjs | null | undefined;
  filterDay?: Dayjs | null | undefined;
  periodType?: 'start' | 'end';
  timePickerProps?: Omit<DateTimePickerProps, 'onChange' | 'value'>;
  inputProps?: DateTimePickerProps['inputProps'];
  onChange: (value: Dayjs | null | undefined) => void;
};

type GridValue = {
  isoDayOfWeek: number;
  startTime: string;
};

const getGridValue = ({ isoDayOfWeek, startTime }: GridValue) => {
  const currentDate = dayjs().set('day', isoDayOfWeek).format('YYYY-MM-DD');
  const currentTime = dayjs(startTime, 'HH:mm').format('HH:mm');

  return dayjs(`${currentDate}T${currentTime}`);
};

const isSameHourAndMinute = (periodA: Dayjs, periodB: Dayjs) =>
  periodA.hour() === periodB.hour() && periodA.minute() === periodB.minute();

export const SelectGridPeriod = forwardRef<
  HTMLInputElement,
  SelectGridPeriodProps
>(
  (
    {
      label,
      timePickerProps,
      filterDay,
      inputProps,
      value,
      periodType = 'start',
      onChange,
    },
    ref,
  ) => {
    const { t } = useTranslation(['common', 'calendar']);

    const id = useId();
    const anchorEl = useRef<HTMLInputElement | null>(null);
    const popperRef = useRef<HTMLDivElement | null>(null);
    const refs = useMergeRefs(ref, anchorEl);
    const clockRef = useRef<HTMLDivElement | null>(null);
    const periodsBtnsRef = useRef<HTMLButtonElement[][]>([]);
    const tabListBtnRef = useRef<HTMLButtonElement[]>([]);

    const { data: gridData = [], isLoading } = useCalendarGridPeriods({});

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [tab, setTab] = useState<string | 'time'>('time');

    useEffect(() => {
      const isValueWithinGrid = gridData
        .flatMap((periodValue) =>
          (periodValue.days || []).flatMap((day) =>
            (day?.periods || []).map((period) => ({
              isoDayOfWeek: day?.isoDayOfWeek ?? 0,
              ...period,
            })),
          ),
        )
        .some(({ isoDayOfWeek, startTime, endTime }) =>
          value?.isSame(
            getGridValue({
              isoDayOfWeek: isoDayOfWeek,
              startTime: periodType === 'start' ? startTime : endTime,
            }),
          ),
        );

      if (isValueWithinGrid || !value) {
        const [initialGrid] = gridData;
        setTab(String(initialGrid?.gridIdx));
      } else {
        setTab('time');
      }
    }, [value, gridData, periodType]);

    const filteredPeriods = useMemo(() => {
      if (!filterDay) return gridData;

      return gridData.map((grid) => ({
        ...grid,
        days: (grid.days || []).filter(
          (day) => day?.isoDayOfWeek === filterDay.day(),
        ),
      }));
    }, [gridData, filterDay]);

    useEffect(() => {
      if (tab === 'time' && !value) {
        if (clockRef.current) {
          const scrollElement = clockRef.current.querySelector(
            '.MuiMultiSectionDigitalClockSection-root',
          );

          scrollElement
            ?.querySelectorAll('.MuiMultiSectionDigitalClockSection-item')
            ?.item(9)
            ?.scrollIntoView({ behavior: 'instant', block: 'start' });

          scrollElement?.scrollBy(0, -4);
        }
      }
    }, [tab, value]);

    const handleKeyDown = (
      event: React.KeyboardEvent<HTMLDivElement>,
      dayIndex: number,
      periodIndex: number,
    ) => {
      const totalDays = periodsBtnsRef.current.length;
      const totalPeriods = periodsBtnsRef.current[dayIndex]?.length || 0;

      const nextDayIndex = (dayIndex + 1) % totalDays;
      const prevDayIndex = (dayIndex - 1 + totalDays) % totalDays;
      const nextPeriodIndex = (periodIndex + 1) % totalPeriods;
      const prevPeriodIndex = (periodIndex - 1 + totalPeriods) % totalPeriods;

      switch (event.key) {
        case 'ArrowRight': {
          periodsBtnsRef.current[nextDayIndex]?.[
            dayIndex + 1 >= totalDays ? nextPeriodIndex : periodIndex
          ]?.focus();
          break;
        }
        case 'ArrowLeft': {
          periodsBtnsRef.current[prevDayIndex]?.[
            dayIndex - 1 < 0 ? prevPeriodIndex : periodIndex
          ]?.focus();
          break;
        }
        case 'ArrowDown': {
          periodsBtnsRef.current[
            periodIndex + 1 >= totalPeriods ? nextDayIndex : dayIndex
          ]?.[nextPeriodIndex]?.focus();
          break;
        }
        case 'ArrowUp': {
          const prevDay = periodIndex - 1 < 0;
          periodsBtnsRef.current[prevDay ? prevDayIndex : dayIndex]?.[
            prevDay
              ? periodsBtnsRef.current[prevDayIndex]?.length - 1
              : prevPeriodIndex
          ]?.focus();
          break;
        }
        case 'Tab': {
          event.preventDefault();
          tabListBtnRef.current?.[1]?.focus();
          break;
        }
        default:
          break;
      }
    };

    return (
      <>
        <DateTimePicker
          {...timePickerProps}
          label={label}
          value={value ?? null}
          onChange={onChange}
          onOpen={onOpen}
          onClose={onClose}
          open={false}
          inputProps={{
            ...inputProps,
            ref: refs,
            id,
            'aria-expanded': isOpen ? 'true' : undefined,
            'aria-controls': isOpen ? `${id}-menu` : undefined,
            'aria-haspopup': 'true',
            onClick: onOpen,
            sx: {
              ...inputProps?.sx,
              '.MuiInputAdornment-root': {
                pointerEvents: 'none',
              },
            },
          }}
        />
        {/* biome-ignore lint/a11y/useValidAriaRole: <explanation> */}
        <Popper
          ref={popperRef}
          open={isOpen}
          anchorEl={anchorEl.current}
          role={undefined}
          placement="bottom-start"
          sx={{ zIndex: 'modal' }}
        >
          <ClickAwayListener onClickAway={onClose}>
            <Card
              variant="soft"
              tabIndex={0}
              sx={{
                maxWidth: '85vw',
                maxHeight: '80vh',
                minHeight: 100,
                overflow: 'auto',
              }}
            >
              <LoadingPlaceholderContainer isLoading={isLoading}>
                <TabContext value={tab ?? ''}>
                  <CardHeader
                    sx={{ p: 0, mx: 3 }}
                    title={
                      <TabList
                        onChange={(_, value: string) => setTab(value ?? '')}
                      >
                        {gridData.map(({ gridIdx, name }, gridIndex) => (
                          <Tab
                            ref={(el) => {
                              if (el) {
                                tabListBtnRef.current[gridIndex] =
                                  el as unknown as HTMLButtonElement;
                              }
                            }}
                            key={gridIdx}
                            value={String(gridIdx)}
                            disableTouchRipple
                            disableRipple={false}
                            tabIndex={0}
                            label={
                              gridData.length > 1 ? name : t('calendar:period')
                            }
                          />
                        ))}
                        <Tab
                          ref={(el) => {
                            if (el) {
                              tabListBtnRef.current[gridData.length] =
                                el as unknown as HTMLButtonElement;
                            }
                          }}
                          disableTouchRipple
                          disableRipple={false}
                          tabIndex={0}
                          value="time"
                          label={t('calendar:time')}
                        />
                      </TabList>
                    }
                  />

                  {filteredPeriods.map((grid) => {
                    return (
                      <TabPanel
                        key={grid.gridIdx}
                        value={String(grid.gridIdx ?? '')}
                        sx={{ p: 0 }}
                      >
                        <Card variant="outlined">
                          <TableContainer sx={{ borderRadius: 1 }}>
                            <Table
                              size="small"
                              sx={({ spacing }) => ({
                                '& th': {
                                  background: 'transparent',
                                  fontWeight: 600,
                                },
                                '& td': {
                                  verticalAlign: 'top',
                                  p: spacing(0.5),
                                },
                              })}
                            >
                              <TableHead>
                                <TableRow>
                                  {grid.days?.map((day) => (
                                    <TableCell key={day?.dayIdx}>
                                      {dayjs()
                                        .set('day', day?.isoDayOfWeek ?? 0)
                                        .format('dddd')}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  {grid.days?.map((day, dayIndex) => (
                                    <TableCell key={day?.dayIdx}>
                                      <List sx={{ p: 0 }}>
                                        {day?.periods.map(
                                          (period, periodIndex) => {
                                            const gridValue = getGridValue({
                                              isoDayOfWeek: day.isoDayOfWeek,
                                              startTime:
                                                periodType === 'start'
                                                  ? period.startTime
                                                  : period.endTime,
                                            });

                                            const isSelected =
                                              filterDay && value
                                                ? isSameHourAndMinute(
                                                    value,
                                                    gridValue,
                                                  )
                                                : value?.isSame(gridValue);

                                            return (
                                              <ListItem
                                                key={period.periodIdx}
                                                disableGutters
                                                dense
                                                sx={{ py: 0.25 }}
                                              >
                                                <Tooltip
                                                  title={period.name}
                                                  followCursor
                                                  disableFocusListener
                                                  placement="right"
                                                >
                                                  <ListItemButton
                                                    ref={(el) => {
                                                      if (el) {
                                                        periodsBtnsRef.current[
                                                          dayIndex
                                                        ] ||= [];
                                                        periodsBtnsRef.current[
                                                          dayIndex
                                                        ][periodIndex] =
                                                          el as unknown as HTMLButtonElement;
                                                      }
                                                    }}
                                                    onKeyDown={(event) =>
                                                      handleKeyDown(
                                                        event,
                                                        dayIndex,
                                                        periodIndex,
                                                      )
                                                    }
                                                    autoFocus={
                                                      isSelected ||
                                                      (dayIndex === 0 &&
                                                        periodIndex === 0)
                                                    }
                                                    aria-selected={isSelected}
                                                    selected={isSelected}
                                                    onClick={() => {
                                                      onChange(gridValue);
                                                      onClose();
                                                    }}
                                                    sx={({ palette }) => ({
                                                      borderRadius: 1,
                                                      border: `1px solid ${
                                                        isSelected
                                                          ? palette.primary.main
                                                          : 'transparent'
                                                      }`,
                                                      backgroundColor:
                                                        isSelected
                                                          ? palette.primary
                                                              .lighter
                                                          : undefined,
                                                    })}
                                                  >
                                                    <ListItemIcon
                                                      sx={{ mr: 1 }}
                                                    >
                                                      {
                                                        iconByGridType[
                                                          period.type
                                                        ]
                                                      }
                                                    </ListItemIcon>
                                                    <ListItemText
                                                      primaryTypographyProps={{
                                                        variant: 'body2',
                                                        noWrap: true,
                                                      }}
                                                      primary={`${period.startTime} - ${period.endTime}`}
                                                    />
                                                  </ListItemButton>
                                                </Tooltip>
                                              </ListItem>
                                            );
                                          },
                                        )}
                                      </List>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Card>
                      </TabPanel>
                    );
                  })}
                  <TabPanel value="time" sx={{ p: 0 }}>
                    <Card variant="outlined">
                      <MultiSectionDigitalClock
                        ref={clockRef}
                        value={value ?? null}
                        onChange={(v) => {
                          onChange(v);
                          onClose();
                        }}
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      />
                    </Card>
                  </TabPanel>
                </TabContext>
              </LoadingPlaceholderContainer>
            </Card>
          </ClickAwayListener>
        </Popper>
      </>
    );
  },
);

if (process.env.NODE_ENV !== 'production') {
  SelectGridPeriod.displayName = 'SelectGridPeriod';
}

type RHFSelectGridPeriodProps<TField extends FieldValues> = Omit<
  SelectGridPeriodProps,
  'onChange'
> & {
  controlProps: UseControllerProps<TField>;
};

export const RHFSelectGridPeriod = <TField extends FieldValues>({
  label,
  timePickerProps,
  inputProps,
  controlProps,
  ...selectGridPeriodProps
}: RHFSelectGridPeriodProps<TField>) => {
  const {
    field: { ref, onBlur, name, value, onChange, disabled },
    fieldState: { error },
  } = useController(controlProps);

  return (
    <SelectGridPeriod
      label={label}
      value={value}
      onChange={onChange}
      {...selectGridPeriodProps}
      timePickerProps={{
        ...timePickerProps,
        inputRef: ref,
        disabled,
      }}
      inputProps={{
        ...inputProps,
        onBlur,
        name,
        error: !!error,
        helperText: error?.message,
      }}
    />
  );
};
