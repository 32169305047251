import {
  type NavObjectFunction,
  NavObjectType,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { RoomClockIcon, WorkflowIcon } from '@tyro/icons';
import { getRulesActionsMeta } from './api/actions-meta';
import { getRuleDetails, getRulesList } from './api/rules';
import { getRulesRuleMeta } from './api/rules-meta';
import { getRulesViewTriggered } from './api/view-triggered';

const RulesListing = lazyWithRetry(() => import('./pages/index'));
const CreateRule = lazyWithRetry(() => import('./pages/create'));
const EditRule = lazyWithRetry(() => import('./pages/edit'));
const ViewRule = lazyWithRetry(() => import('./pages/view'));

const upsertRuleLoader = () => {
  return Promise.all([getRulesActionsMeta(), getRulesRuleMeta()]);
};

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:management.title'),
    children: [
      {
        type: NavObjectType.RootLink,
        path: 'rules',
        title: t('navigation:management.rules'),
        icon: <WorkflowIcon />,
        hasAccess: ({ isTyroUser }) => isTyroUser,
        children: [
          {
            type: NavObjectType.NonMenuLink,
            index: true,
            element: <RulesListing />,
            loader: () => getRulesList({ ruleIds: [] }),
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'create',
            element: <CreateRule />,
            loader: upsertRuleLoader,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'edit/:id',
            element: <EditRule />,
            loader: ({ params }) => {
              const ruleId = getNumber(params.id);

              if (!ruleId) {
                throw404Error();
              }

              return Promise.all([
                getRuleDetails({ ruleIds: [ruleId] }),
                upsertRuleLoader(),
              ]);
            },
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'view/:id',
            element: <ViewRule />,
            loader: ({ params }) => {
              const ruleId = getNumber(params.id);

              if (!ruleId) {
                throw404Error();
              }

              return getRulesViewTriggered({
                ruleIds: [ruleId],
                page: { limit: 100, offset: 0 },
              });
            },
          },
        ],
      },
    ],
  },
];
