import { useQuery } from '@tanstack/react-query';
import {
  type CalendarGridFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { calendarKeys } from './keys';

const calendarGridPeriods = graphql(/* GraphQL */ `
  query calendarGridPeriods($filter: CalendarGridFilter) {
    calendar_grids(filter: $filter) {
      gridIdx
      name
      days {
        isoDayOfWeek
        dayIdx
        startTime
        endTime
        periods {
          gridIdx
          periodIdx
          name
          startTime
          endTime
          type
        }
      }
    }
  }
`);

const calendarGridPeriodsQuery = (filter: CalendarGridFilter) => ({
  queryKey: calendarKeys.gridPeriods(filter),
  queryFn: async () =>
    gqlClient.request(calendarGridPeriods, {
      filter,
    }),
});

export function getCalendarGridPeriods(filter: CalendarGridFilter) {
  return queryClient.fetchQuery(calendarGridPeriodsQuery(filter));
}

export function useCalendarGridPeriods(filter: CalendarGridFilter) {
  return useQuery({
    ...calendarGridPeriodsQuery(filter),
    select: ({ calendar_grids }) => calendar_grids ?? [],
  });
}

export type ReturnTypeFromCalendarGridPeriods = UseQueryReturnType<
  typeof useCalendarGridPeriods
>[number];
